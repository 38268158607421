<template>
    <div class="container-fluid full-section">
        <div class="container">
            <div class="row contact-row">
                <div class="col col-lg-2">
                    <h5 id="contact-title">Контакты</h5>
                </div>
                <div class="col col-lg-4 text-md-right">
                    <div id="subject-select-container">
                        <select v-model="selected" @change="findByName" class="contacts-select mt-3">
                            <option
                                v-for="option in options"
                                v-bind:value="option.name"
                                :selected="option.name === selected"
                                :key="option.id"
                            >
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <textarea class="input_style" v-model="message" type="text"
                              placeholder="Сообщение в техническую поддержку"></textarea>
                    <button class="send-to-basket" type="button" @click="sendEmail">Отправить</button>
                </div>
                <div class="col-md-6">
                    <div class="address_">
                        <div class="ss-body pb-0" v-html="contact.content"></div>
                        <div class="ss-body p-0" v-if="contact.contacts">
                            <ul class="list-unstyled contacts">
                                <li v-for="item in contact.contacts" :key="item.id" class="mb-3">
                                    <span class="d-inline-block mb-2">{{ item.full_name }}</span>
                                    <ul class="list-unstyled">
                                        <li v-html="addSpace(item.phone)" />
                                        <li>{{ item.email }}</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {get, post} from '@/lib/axios';
import {showSuccess} from "@/lib/swal2";

export default {
    name: "Contacts",
    data: () => ({
        options: [],
        selected: 'Техническая поддержка',
        message: '',
        contact: [],
    }),
    methods: {
        findByName() {
            post('support/find-by-name', {'topic': this.selected}).then(response => {
                this.contact = response.data.data
            })
        },
        addSpace(word) {
          return word.replace(' +', `<br /> +`)
        },
        async fetchData() {
            await get('support').then(response => {
                this.options = response.data.data

                if (this.options) {
                    let selected;

                    if (this.isCurrentUserDealer) // если для дилера
                        selected = this.options.find(c => c.name == 'Техническая поддержка');
                    else
                        selected = this.options.find(c => c.name == 'Вопрос по работе портала');

                    if (selected) {
                        this.selected = selected.name;
                    }
                }

                this.findByName()
            })
        },
        async sendEmail() {
            await post('support/send', {'message': this.message}).then(() => {
                showSuccess('Ваше сообщение успешно отправлено!')
            })
        }
    },
    mounted() {
        this.$router.push('/profile-contacts')
        this.fetchData()
    },
    metaInfo() {
        return {
            title: `Контакты - ЛУКМАРКЕТ`,
        }
    },
}
</script>

<style scoped>

.list-unstyled li {
  line-height: 125%;
}

.contact-row {
    margin-top: 40px;
}

h5 {
    text-transform: uppercase;
    margin: 13px 0 20px 0;
    font-weight: bold;
    font-size: 25px;
    font-family: "Futuris";
}

.input_style {
    padding: 20px;
    margin-bottom: 25px;
    width: 100%;
    height: 300px;
    resize: none;
}

.address_ {
    border-left: 2px solid #ededed;
    padding-left: 15px;
}

.address_ p {
    display: block;
    font-size: 19px;
    line-height: 1.5;
    text-align: left;
    font-weight: bold
}

ul.address {
    list-style: none;
    padding-left: 0;
}

ul.address a:link,
ul.address a:visited,
ul.address a {
    color: #444;
}

ul.address li a {
    display: block;
    padding-bottom: 30px;
    font-size: 17px;
    line-height: 1.5;
    text-align: left;
}

.send-to-basket {
    margin-bottom: 10px;
}

ul.address li:first-child a {
    font-weight: bold;
}

#contact-title {
    display: inline-block;
}

#subject-select-container {
    display: inline-block;
}

#subject-select-container > .field {
    width: 81vw;
    max-width: 320px;
}

.red-text {
    color: rgb(229, 23, 63);
}

@media screen and (min-width: 767px) {
    .address_ {
        min-height: 300px;
    }

    .address_ p {
        padding-bottom: 30px;
    }
}

@media screen and (min-width: 992px) {
    .full-section {
        min-height: calc(100vh - 309px) !important;
    }
}

.contacts-select {
    padding: 6px;
    margin-top: 1rem;
}

@media screen and (max-width: 530px) {
    .contacts-select {
        margin-top: 0;
    }

    .input_style {
        margin-top: 15px;
        margin-bottom: 0;
    }

    .send-to-basket {
        margin-top: 15px;
    }
}

</style>
